<template>
  <div class="hs-docs">
    <p class="student-year__info">
      Все документы необходимо загружать с персонального компьютера или ноутбука
      во избежание технических неполадок
    </p>
    <div class="docs-components__body">
      <PDFInputFile
        label="Портфолио"
        name="Выбрать файл"
        :sizeFile="portfolioSize"
        :tooltip="hsTooltipsTexts['portfolio']"
        :file-loaded="
          hasDocument('portfolio')?.url_original ||
          hasStateDocument('portfolio').url
        "
        :link-portfolio="hasDocument('portfolio')"
        :hasFile="isDisabled || isBtnDisabled"
        @file-change="
          (value) => {
            saveDocument(value, 'portfolio');
          }
        "
        type="portfolio"
        itemType="portfolio"
      />
      <PDFInputFile
        label="Анкета участника"
        name="Выбрать файл"
        :sizeFile="10"
        :tooltip="hsTooltipsTexts['anketa']"
        type="portfolio"
        itemType="portfolio"
        :file-loaded="
          hasDocument('anketa_client')?.url_original ||
          hasStateDocument('anketa_client').url
        "
        :link-portfolio="hasDocument('anketa_client')"
        :hasFile="isDisabled || isBtnDisabled"
        @file-change="
          (value) => {
            saveDocument(value, 'anketa_client');
          }
        "
      />
      <PDFInputFile
        label="Скан финансово-лицевого счета с указанием жилой и общей площади жилого помещения и датой выдачи"
        name="Выбрать файл"
        :sizeFile="2"
        :tooltip="hsTooltipsTexts['skan1']"
        type="portfolio"
        itemType="portfolio"
        :file-loaded="
          hasDocument(
            'financial_personal_account_indicating_residential_total_area'
          )?.url_original ||
          hasStateDocument(
            'financial_personal_account_indicating_residential_total_area'
          ).url
        "
        :link-portfolio="
          hasDocument(
            'financial_personal_account_indicating_residential_total_area'
          )
        "
        :hasFile="isDisabled || isBtnDisabled"
        @file-change="
          (value) => {
            saveDocument(
              value,
              'financial_personal_account_indicating_residential_total_area'
            );
          }
        "
      />
      <PDFInputFile
        label="Скан справки из Главного управления Федеральной регистрационной службы по Республике Татарстан (для всех членов семьи - заявитель, супруг(а), дети)"
        name="Выбрать файл"
        :sizeFile="2"
        :tooltip="hsTooltipsTexts['skan2']"
        type="portfolio"
        itemType="portfolio"
        :file-loaded="
          hasDocument(
            'certificate_main_directorate_federal_registration_service_republic_tatarstan_all_family'
          )?.url_original ||
          hasStateDocument(
            'certificate_main_directorate_federal_registration_service_republic_tatarstan_all_family'
          ).url
        "
        :link-portfolio="
          hasDocument(
            'certificate_main_directorate_federal_registration_service_republic_tatarstan_all_family'
          )
        "
        :hasFile="isDisabled || isBtnDisabled"
        @file-change="
          (value) => {
            saveDocument(
              value,
              'certificate_main_directorate_federal_registration_service_republic_tatarstan_all_family'
            );
          }
        "
      />
    </div>

    <!--    <PDFInputFile-->
    <!--      label="Необходимость улучшения жилищных условий"-->
    <!--      name="Выбрать файл"-->
    <!--      :sizeFile="2"-->
    <!--      type="portfolio"-->
    <!--      itemType="portfolio"-->
    <!--      :file-loaded="-->
    <!--        hasDocument('certificate_main_need_to_improve_living_conditions')-->
    <!--          ?.url_original ||-->
    <!--        hasStateDocument('certificate_main_need_to_improve_living_conditions')-->
    <!--          .url-->
    <!--      "-->
    <!--      :link-portfolio="-->
    <!--        hasDocument('certificate_main_need_to_improve_living_conditions')-->
    <!--      "-->
    <!--      :hasFile="isDisabled || isBtnDisabled"-->
    <!--      @file-change="-->
    <!--        (value) => {-->
    <!--          saveDocument(-->
    <!--            value,-->
    <!--            'certificate_main_need_to_improve_living_conditions'-->
    <!--          );-->
    <!--        }-->
    <!--      "-->
    <!--    />-->
    <p class="student-year__info">
      Чтобы преобразовать изображение в формат PDF или сжать файл любого
      формата, воспользуйтесь поисковой системой для нахождения соответствующих
      <a
        href="https://ya.ru/search/?text=%D0%BA%D0%BE%D0%BD%D0%B2%D0%B5%D1%80%D1%82%D0%B5%D1%80+%D0%B2+pdf&lr=236"
        target="_blank"
      >
        онлайн-сервисов
      </a>
    </p>
    <div class="student-year__btn">
      <Button
        @click="sendStatement"
        :loading="isBtnLoading || isSending"
        :disabled="!allDocUploaded"
        v-if="!isDisabled"
      >
        Подать заявку
      </Button>
      <Button
        v-if="rollbackAllowed"
        @protected-click="openPopup"
        @split-click="openPopup"
        secondary
        :loading="isDeleteBtnLoading"
        split-icon="Help"
      >
        Отказ от участия
      </Button>
      <Button secondary v-if="false">Сохранить как черновик</Button>
    </div>
    <div class="student-year__comments" v-if="commentsList?.length > 0">
      <div
        class="student-year__comments-title"
        v-if="currentCategoryData?.expert_comments?.data?.length > 0"
      >
        Комментарии от экспертов
      </div>
      <div class="student-year__comments-list">
        <div
          class="student-year__comments-item"
          v-for="(item, index) in commentsList"
          :key="index"
        >
          <p>{{ item?.expert_info?.fio }}</p>
          <span>{{ item?.comment }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PDFInputFile from "@/components/Blocks/PDFInputFile";
import { mapGetters, mapState } from "vuex";
import hsTooltipsTexts from "@/enums/hsTooltipsTexts";
import moment from "moment";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import Button from "@/components/Blocks/Button";
export default {
  name: "HousingStockDocs",
  components: { Button, PDFInputFile },
  props: ["currentProject"],
  data() {
    return {
      hsTooltipsTexts,
      isBtnDisabled: false,
      isBtnLoading: false,
      isSending: false,
      statementDocs: [],
      formData: {
        portfolio: "",
        anketa_client: "",
        financial_personal_account_indicating_residential_total_area: "",
        certificate_main_directorate_federal_registration_service_republic_tatarstan_all_family:
          "",
        // certificate_main_need_to_improve_living_conditions: "",
      },
    };
  },
  watch: {
    HSDocsList: {
      handler: function () {
        Object.keys(this.formData).forEach((key) => {
          this.formData[key] = this.HSDocsList[key].url;
        });
      },
      deep: true,
    },
  },
  computed: {
    allDocUploaded() {
      let bufferArr = [];
      Object.keys(this.formData).forEach((key) => {
        if (this.hasDocument(key) || this.hasStateDocument(key)?.url) {
          bufferArr.push(true);
        } else bufferArr.push(false);
      });
      return bufferArr.every((item) => {
        return item;
      });
    },
    projectTreeId() {
      return this.currentProject.settings.trees.data[0].id;
    },
    isDisabled() {
      let boolean;
      if (Object.values(this.currentCategoryData).length > 0) {
        if (this.validPeriodForRollback) {
          if (this.currentCategoryData?.status?.key === 0) {
            boolean = false;
          } else boolean = this.currentCategoryData?.status?.key !== 35;
        } else if (this.validPeriodFor35Status) {
          boolean = this.currentCategoryData?.status?.key !== 35;
        } else return true;
      }
      return this.isOldApplication || boolean;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    statuses() {
      return JSON.parse(
        JSON.stringify(
          this.storage?.systemInfo?.list_statuses[
            `${this.currentCategoryData?.info_project?.key}`
          ] ?? []
        )
      );
    },
    currentStatus() {
      return this.statementId
        ? "0"
        : Object.keys(this.statuses).find((item) => {
            return +item === this.currentCategoryData?.status?.key;
          });
    },
    rollbackAllowed() {
      let boolean;
      if (this.validPeriodForRollback) {
        if (
          this.currentProject?.id === 1 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (this.currentProject?.id === 4) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
      } else if (this.validPeriodFor35Status) {
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
      } else boolean = false;

      return this.isOldApplication ? false : boolean;
    },
    commentsList() {
      return this.currentCategoryData?.expert_comments?.data.filter((item) => {
        return item.comment;
      });
    },
    ...mapState([
      "currentCategoryData",
      "statementId",
      "statementsList",
      "HSDocsList",
      "isOldApplication",
      "isDeleteBtnLoading",
      "storage",
    ]),
    ...mapState("user", ["disabledFields"]),
    ...mapGetters(["portfolioSize"]),
  },
  methods: {
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupRevokeApplication",
        isShown: true,
        props: {
          id: this.statementId
            ? this.statementId
            : this.currentCategoryData?.id,
          fromProject: true,
        },
      });
    },
    async saveDocument(value, key) {
      await this.$store.commit("setHSDoc", [key, value]);
      this.formData[key] = await value;

      this.isBtnLoading = true;
      this.isBtnDisabled = true;

      await createRequest(requestConfigs.POSTSaveDocument, {
        jsonPayload: {
          file: this.formData[key],
        },
      })
        .then((response) => {
          this.$store.commit("pushToTray", {
            text: "Документ сохранен",
            type: "success",
          });
          this.savedDoc = response.data;
          this.savedDoc.key = key;
          this.$store.commit("setHSDocId", [key, response.data.id]);
          this.$store.commit("setHSDocUrl", [key, response.data.url_original]);
        })
        .then(() => {
          let payloadObject = {};
          payloadObject.media_id = this.HSDocsList[key].id;
          payloadObject.project_tree_id = this.projectTreeId;
          payloadObject.key = key;

          createRequest(requestConfigs.POSTAddDocument, {
            jsonPayload: payloadObject,
            routerPayload: {
              id: this.statementId || this.currentCategoryData.id,
            },
          })
            .then(() => {
              this.$store.commit("pushToTray", {
                text: "Документ прикреплен к заявке",
                type: "success",
              });
              if (this.statementDocs && this.statementDocs.length > 0) {
                let buffer = JSON.parse(JSON.stringify(this.statementDocs));
                buffer = buffer.filter((i) => {
                  return i.key === key;
                });
                if (buffer && buffer.length > 0) {
                  buffer.forEach(async (item) => {
                    await createRequest(requestConfigs.DELStatementDocument, {
                      jsonPayload: { media_id: item.id },
                      routerPayload: {
                        id: this.statementId || this.currentCategoryData.id,
                      },
                    });
                    this.statementDocs = this.statementDocs.filter((i) => {
                      return i.id !== item.id;
                    });
                  });
                  this.statementDocs.push(this.savedDoc);
                  this.savedDoc = {};
                }
              }
            })
            .catch((error) => {
              this.$store.commit("pushToTray", {
                text: error.errors[0].error_descr,
                type: "error",
              });
            })
            .finally(() => {
              this.isBtnDisabled = false;
              this.isBtnLoading = false;
            });
        })
        .catch((error) => {
          error?.errors?.length > 0
            ? this.$store.commit("pushToTray", {
                text: error.errors[0].error_descr,
                type: "error",
              })
            : this.$store.commit("pushToTray", {
                text: "Неверный формат или размер файла",
                type: "error",
              });
          this.isBtnDisabled = false;
          this.isBtnLoading = false;
        })
        .finally(() => {
          this.isBtnDisabled = false;
          this.isBtnLoading = false;
        });
    },
    async sendStatement() {
      if (!this.allDocUploaded) {
        this.$store.commit("pushToTray", {
          text: "Все документы должны быть загружены",
          type: "error",
        });
        return;
      }
      this.isSending = true;
      await createRequest(requestConfigs.POSTSendStatement, {
        routerPayload: { id: this.statementId || this.currentCategoryData.id },
      })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Заявка успешно создана",
            type: "success",
          });
          this.$store.commit("setStatementId", "");
          this.$router.push({ name: "Applications" });
          this.$store.commit("clearHSDocs");
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    hasDocument(key) {
      let buffer = JSON.parse(JSON.stringify(this.statementsList));

      buffer = buffer.find((item) => {
        return this.statementId
          ? item.id === this.statementId
          : item.id === this.currentCategoryData?.id;
      });

      return buffer?.info_documents?.data
        ?.filter((doc) => {
          return doc.key === key;
        })
        .sort((a, b) => {
          return (
            moment(a.info_document?.created_at) -
            moment(b.info_document?.created_at)
          );
        })
        .slice(-1)[0]?.info_document;
    },
    hasStateDocument(key) {
      return this.HSDocsList[key];
    },
  },
  mounted() {
    if (this.HSDocsList) {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = this.HSDocsList[key].url;
      });
    }
    if (this.statementsList) {
      let buffer = JSON.parse(JSON.stringify(this.statementsList));

      buffer = buffer.find((item) => {
        return this.statementId
          ? item.id === this.statementId
          : item.id === this.currentCategoryData?.id;
      });
      this.statementDocs = buffer?.info_documents?.data.map((item) => {
        item.id = item.info_document.id;
        return item;
      });
    }
  },
};
</script>

<style lang="scss">
.hs-docs {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  .docs-upload {
    @include adaptive(tablet-small) {
      &--left {
        max-width: unset;
      }
    }
  }
}
</style>
